<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
       <v-card>
          <v-card-text>
            <v-row dense class="pb-4">
              <v-col cols="12" md="6" order="2" order-md="1">
                <v-text-field
                  label="Search"
                  append-icon="fas fa-search"
                  single-line
                  hide-details
                  v-model="search"
                  clearable
                  color="color3"
                  solo-inverted
                ></v-text-field>
              </v-col>
              <v-col class="text-right" cols="12" md="6" order="1" order-md="2">
                <v-btn color="color3Text color3--text" @click.stop="showFilter = !showFilter" :loading="loading" fab small class="mr-1">
                  <v-icon>fas fa-filter</v-icon>
                </v-btn>
                <v-btn color="color3Text color3--text" @click.stop="onDownloadClick" :loading="loading" fab small class="mr-1">
                  <v-icon>fas fa-cloud-download-alt</v-icon>
                </v-btn>
                <v-btn color="color3Text color3--text" @click.stop="loadMembers" :loading="loading" fab small>
                  <v-icon>fas fa-sync-alt</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-expand-transition>
              <div v-if="showFilter">
                <v-row dense>
                  <v-col cols="12" md="6">
                    <v-select
                      :items="levels"
                      item-text="name"
                      item-value="id"
                      v-model="selectedLevels"
                      label="Levels"
                      hint="Choose levels to include"
                      persistent-hint
                      multiple
                      chips
                      deletable-chips
                      color="color3"
                      item-color="color3"
                      ref="select1"
                    >
                      <v-list-item
                        slot="prepend-item"
                        ripple
                        @click="toggleLevel"
                        :color="icon.color"
                      >
                        <v-list-item-action>
                          <v-icon :color="icon.color">{{icon.name}}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item>
                      <v-divider
                        slot="prepend-item"
                        class="mt-2"
                      ></v-divider>
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-select
                      :items="statuses"
                      item-text="name"
                      item-value="id"
                      v-model="selectedStatuses"
                      label="Status"
                      hint="Choose Statuses to include"
                      persistent-hint
                      multiple
                      chips
                      deletable-chips
                      color="color3"
                      item-color="color3"
                      ref="select2"
                    >
                      <v-list-item
                        slot="prepend-item"
                        ripple
                        @click="toggleStatus"
                        :color="icon.color"
                      >
                        <v-list-item-action>
                          <v-icon :color="icon.color">{{icon.name}}</v-icon>
                        </v-list-item-action>
                        <v-list-item-title>Select All</v-list-item-title>
                      </v-list-item>
                      <v-divider
                        slot="prepend-item"
                        class="mt-2"
                      ></v-divider>
                    </v-select>
                  </v-col>
                </v-row>
              </div>
            </v-expand-transition>
            <v-data-table
              :items="filteredData"
              :headers="headers"
              :loading="loading"
              :options.sync="pagination"
              :footer-props="{itemsPerPageOptions: [50, 100, 250]}"
              :search="search"
              class="elevation-1"
            >
              <template v-slot:[`item.profilePic`]="{ item }">
                <v-img
                  height="48"
                  width="48"
                  v-if="item.profilePic"
                  :src="item.profilePic"
                  @click.stop="selectedPic = item.profilePic; picDialog = true"
                ></v-img>
              </template>
              <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    :color="`${item.status === 'Active' ? 'success' : 'error'} white--text`"
                  >{{item.status}}</v-chip>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-tooltip left v-if="!$vuetify.breakpoint.xsOnly">
                  <span>View {{item.firstName}}'s profile</span>
                  <template v-slot:activator="{on}">
                    <v-btn
                      color="color3 color3Text--text"
                      small
                      fab
                      v-on="on"
                      :to="{
                        name: 'member-profile',
                        params: {
                          id: item.id,
                          playerProfileId: item.playerProfileId,
                          memberId: item.id,
                          name: `${item.firstName} ${item.lastName}`,
                          profilePic: item.profilePic
                        }
                      }"
                    >
                      <v-icon small>fas fa-caret-right</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
                <v-btn
                  color="color3 color3Text--text"
                  small
                  text
                  v-else
                  :to="{
                    name: 'member-profile',
                    params: {
                      id: item.id,
                      playerProfileId: item.playerProfileId,
                      memberId: item.id,
                      name: `${item.firstName} ${item.lastName}`,
                      profilePic: item.profilePic
                    }
                  }"
                >
                  View {{item.firstName}}'s profile <v-icon class="ml-3">fas fa-caret-right</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
       </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="picDialog"
      max-width="500px"
      max-height="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-text class="pa-3">
          <v-img
            v-if="selectedPic"
            :src="selectedPic"
          ></v-img>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Papa from 'papaparse'
import { usPhone, localDt } from '@/Filters'
import moment from 'moment'

export default {
  props: ['username'],
  data () {
    return {
      search: null,
      loading: false,
      data: [],
      headers: [
        { text: 'Member #', value: 'id', align: 'left' },
        { text: 'Pic', value: 'profilePic', align: 'center' },
        { text: 'First Name', value: 'firstName', align: 'left' },
        { text: 'Last Name', value: 'lastName', align: 'left' },
        { text: 'City', value: 'city', align: 'left' },
        { text: 'State', value: 'state', align: 'left' },
        { text: 'Level', value: 'level', align: 'left' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: '', value: 'actions', sortable: false }
      ],
      pagination: { sortBy: ['id'], itemsPerPage: 50 },
      picDialog: null,
      selectedPic: null,
      showFilter: false,
      selectedLevels: [],
      selectedStatuses: []
    }
  },
  computed: {
    levels () {
      return this.data ? [...new Set(this.data.map(m => m.level))] : []
    },
    statuses () {
      return this.data ? [...new Set(this.data.map(m => m.status))] : []
    },
    allDivisions () {
      return this.selectedLevels.length === this.levels.length
    },
    someDivisions () {
      return this.selectedLevels.length > 0 && !this.allDivisions
    },
    allStatuses () {
      return this.selectedStatuses.length === this.statuses.length
    },
    someStatuses () {
      return this.selectedStatuses.length > 0 && !this.allStatuses
    },
    icon () {
      if (this.allDivisions) return { name: 'fas fa-check-square', color: 'color3' }
      if (this.someDivisions) return { name: 'fas fa-minus-square', color: 'color3' }
      return { name: 'far fa-square', color: '' }
    },
    filteredData () {
      if (!this.data || !this.data.length) return []
      let d = this.data
      if (this.someDivisions) {
        d = d.filter(f => this.selectedLevels.includes(f.level))
      }
      if (this.someStatuses) {
        d = d.filter(f => this.selectedStatuses.includes(f.status))
      }
      return d
    },
    downloadData () {
      return this.filteredData.map(m => {
        return {
          'Member Number': m.id,
          Level: m.level,
          Status: m.status,
          'First Name': m.firstName,
          'Last Name': m.lastName,
          Address: m.address,
          'Address 2': m.address2,
          City: m.city,
          State: m.state,
          Zip: m.zip,
          Country: m.country,
          Email: m.email,
          Phone: usPhone(m.phone),
          'Shirt Size': m.shirtSize,
          'Parent First Name': m.parent ? m.parent.firstName : '',
          'Parent Last Name': m.parent ? m.parent.lastName : '',
          'Parent Phone': m.parent ? usPhone(m.parent.phone) : '',
          'Parent Email': m.parent ? m.parent.email : '',
          'Registered On': localDt(m.dtRegistered),
          Gender: m.male ? 'Male' : 'Female',
          DOB: m.dob ? moment(m.dob.replace('Z', '')).format('L') : null,
          'Grad Year': m.gradYear,
          Expires: localDt(m.expires),
          Club: m.club
        }
      })
    }
  },
  methods: {
    onDownloadClick () {
      var fileName = `${this.username} Member List.csv`
      var data = this.downloadData
      this.createFileForDownload(data, fileName)
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    toggleLevel () {
      this.$nextTick(() => {
        if (this.allDivisions) {
          this.selectedLevels = []
        } else {
          this.selectedLevels = [...new Set(this.data.map(m => m.level))]
        }
        if (this.$refs.select1) this.$refs.select1.blur()
        if (this.$refs.select2) this.$refs.select2.blur()
      })
    },
    toggleStatus () {
      this.$nextTick(() => {
        if (this.allStatuses) {
          this.selectedStatuses = []
        } else {
          this.selectedStatuses = [...new Set(this.data.map(m => m.status))]
        }
        if (this.$refs.select1) this.$refs.select1.blur()
        if (this.$refs.select2) this.$refs.select2.blur()
      })
    },
    loadMembers () {
      this.loading = true
      this.$VBL.get.memberList(this.username, '')
        .then(r => {
          this.data = r.data
          this.selectedLevels.push(...new Set(this.data.map(m => m.level)))
          this.selectedStatuses.push(...new Set(this.data.map(m => m.status)))
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    }
  },
  mounted () {
    this.loadMembers()
  }
}
</script>

<style>

</style>
